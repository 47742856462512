@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-Medium.eot');
    src: url('AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
        url('AvenirNext-Medium.woff2') format('woff2'),
        url('AvenirNext-Medium.woff') format('woff'),
        url('AvenirNext-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-Bold.eot');
    src: url('AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),
        url('AvenirNext-Bold.woff2') format('woff2'),
        url('AvenirNext-Bold.woff') format('woff'),
        url('AvenirNext-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-DemiBold.eot');
    src: url('AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('AvenirNext-DemiBold.woff2') format('woff2'),
        url('AvenirNext-DemiBold.woff') format('woff'),
        url('AvenirNext-DemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('AvenirNext-Regular.eot');
    src: url('AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
        url('AvenirNext-Regular.woff2') format('woff2'),
        url('AvenirNext-Regular.woff') format('woff'),
        url('AvenirNext-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

